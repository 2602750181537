
import './scrollbar.scss';

import { defineComponent } from 'vue';
import SimpleBar from 'simplebar';

export default defineComponent({
  name: 'AppScrollbar',
  props: {
    paddingTop: {
      type: Number,
    },
    autoHide: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const simpleBar: unknown = {};

    return { simpleBar };
  },
  computed: {
    scrollElement(): HTMLElement {
      const $refs = this.$refs as { scrollElement: HTMLElement };

      return $refs.scrollElement;
    },
    contentElement(): HTMLElement {
      const $refs = this.$refs as { contentElement: HTMLElement };

      return $refs.contentElement;
    },
  },
  mounted() {
    const $refs = this.$refs as { element: HTMLElement };

    this.simpleBar = new SimpleBar($refs.element, {
      autoHide: this.autoHide,
    });
  },
});
